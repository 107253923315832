import React from 'react';
const Oval1 = ({ width = '891', className = '', height = '891', viewBox='0 0 891 891' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="LOTOOval1-linearGradient-1">
        <stop stopColor="#FCEEE9" offset="0%"/>
        <stop stopColor="#ECF8F8" offset="100%"/>
      </linearGradient>
    </defs>
    <g id="LOTOOval1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.7">
      <circle id="Oval1Circle" fill="url(#LOTOOval1-linearGradient-1)" fillRule="nonzero" cx="445.5" cy="445.5" r="445.5"/>
    </g>
  </svg>;

  export default Oval1;