import React from "react";
import Plx from "react-plx";
import styled from "@emotion/styled";
import { breakpoints } from "../../../utils/style";
import Oval1 from '../../../icons/svg/Backgrounds/Oval1';


const ParallaxContainer = styled("div")`
  position: relative;
  z-index: -100;
  .bg {
    display: block;
    width: 100%;
    height: 50%;
    position: fixed;
    top: 0;
    color: #000;
  }
  .bg-one {
    top: -80px;
    left: 0;
    width: 100%;
  }
  .bg-two {
    right: 0;
    top: 0;
    width: 100%;
    .gatsby-image-wrapper {
      margin-left: -10%;
    }
  }
  
  .oval-wrapper {
    position: relative;
    outline: 1px solid green;
    height: 50vh;
    overflow: visible;
  }
  
  .oval {
    position: absolute;
    max-width: 60%;
  }
  
  .oval-1 {
    top: -400px;
    right: -20%;
  }
  
  .oval-2 {
    top: 0;
    left: -420px;
  }
  
  .oval-3 {
    left: 90%;
    bottom: -90%;
  }
  
  
  @media (max-width: ${breakpoints.sm}) {
    .oval {
      max-width: 80%;
    }
  
    .oval-2 {
      top: 0;
      left: -150px;
    }
    
    .bg-one {
      top: -100px;
      max-height: 100vh;
    }
  }
`;

const ParallaxHome = () => {
  const image1ParallaxData = [
    {
      start: 0,
      duration: 4500,
      properties: [
        {
          startValue: 250,
          endValue: 100,
          property: "translateY",
        },
      ],
    },

  ];

  const image2ParallaxData = [
    {
      start: 0,
      duration: 5500,
      properties: [
        {
          startValue: -50,
          endValue: 1000,
          property: "translateY",
        },
      ],
    },
  ];

  return (
    <ParallaxContainer>
      <Plx className="bg bg-one" parallaxData={image1ParallaxData}>
        <Oval1 width={950} height={950} className={"oval left oval-2"}/>
        <Oval1 width={300} height={300} className={"oval left oval-3"}/>

      </Plx>
      <Plx className="bg bg-two" parallaxData={image2ParallaxData}>
        <Oval1 width={890} height={890} className={"oval right oval-1"}/>
        {/*<Oval1 width={500} height={500} className={"oval left oval-2"}/>*/}
        {/*<Oval1 width={850} height={850} className={"oval right oval-3"}/>*/}
        {/*<Oval1 width={550} height={650} className={"oval left oval-4"}/>*/}
      </Plx>
    </ParallaxContainer>
  )
};

export default ParallaxHome
